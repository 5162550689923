.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.table {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;
}

.table th {
  padding: 16px !important;
  color: #8c8a94;
  font-weight: 400;
  text-align: left;
}

.table td {
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
  border-top: solid #f3f3f4 1px;
}

.inputCheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.segmentsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
