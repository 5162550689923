.root {
  position: relative;
}

.icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.baseFont {
  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.iconPadding {
  padding: 0px 12px 0px 40px;
}

.basePadding {
  padding: 0px 16px;
}

.base {
  height: 40px;
  border-radius: var(--radius-s, 12px);
  border: 1px solid var(--outline-low_em, #eeeeed);
  background: var(--surface-surface_0, #f2f2f1);
}

.base:hover {
  border: 1px solid var(--outline-med_em, #eeeeed);
}

.base:focus-within {
  border: 1px solid var(--outline-high_em, #eeeeed);
}

.errorMessage {
  padding-left: 8px;

  color: var(--typo-primary, #f35454);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
